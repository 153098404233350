<template>
  <div>
    <div class="widgetContainer widgetContainer--full-screen widgetContainer--center welcome">
      <div class="welcome__logo set-logo" />
      <div class="welcome__header">
        <p data-testid="welcome-txt">
          {{ $t('welcome_not_registered_title') }}
        </p>
        <span data-testid="welcome-desc">{{ $t('welcome_not_registered_desc') }}</span>
      </div>

      <el-button type="primary" :style="{background: primaryColor}"
                 class="welcome__get-started el-button__brand brand"
                 @click="goBack()">
        {{ $t('welcome_not_registered_buttonTitle') }}
      </el-button>
    </div>
  </div>
</template>

<script>

import {getBrandConfig} from '@/utils/env';

export default {
  name: 'LoginNotRegistered',
  methods: {
    goBack() {
      window.location.href = getBrandConfig('homePage');
    }
  }
}

</script>
